<template>
  <c-step
    stepperGrpCd="IIM_ACCIDENT_STEP_CD"
    :currentStepCd="currentStepCd"
    :param="param"
    :setSizeAgain="setSize"
    v-model="stepCd"
    @keySetting="keySetting"
    @currentStep="currentStep"
    @closePopup="closePopup"
   >
  </c-step>
</template>

<script>
export default {
  name: 'process-accident-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        stepCd: '',
      }),
    },
    setSize: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editable: true,
      stepCd: '',
      currentStepCd: '',
      uid: '',
      regUpdateBtnData: {
        title: '사고등록',
        flag: false,
        research: '',
        planResearch: '',
        recResearch: '',
      },
    };
  },
  watch: {
  },
  computed: {
    param() {
      return {
        iimAccidentId: this.popupParam.iimAccidentId,
        stepCd: this.popupParam.stepCd,
        regUpdateBtnData: this.regUpdateBtnData,
      }
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.setStep();
    },
    setStep() {
      if (!this.popupParam.stepCd || this.popupParam.stepCd === 'ISPC000001') {
        this.stepCd = 'IASC000001'
        this.currentStepCd = 'IASC000001'
      } else if (this.popupParam.stepCd === 'ISPC000003' || this.popupParam.stepCd === 'ISPC000004') {
        this.stepCd = 'IASC000010'
        this.currentStepCd = 'IASC000010'
      } else {
        this.stepCd = 'IASC000015'
        this.currentStepCd = 'IASC000015'
      }
    },
    currentStep(step) {
      if (!step || step === 'ISPC000001') {
        this.stepCd = 'IASC000001'
        this.currentStepCd = 'IASC000001'
      } else if (step === 'ISPC000003' || step === 'ISPC000004') {
        this.stepCd = 'IASC000010'
        this.currentStepCd = 'IASC000010'
      } else {
        this.stepCd = 'IASC000015'
        this.currentStepCd = 'IASC000015'
      }
      this.popupParam.stepCd = step;
    },
    closePopup() {
      this.$emit('closePopup')
    },
    keySetting(key) {
      this.popupParam.iimAccidentId = key
    },
  }
};
</script>
